import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useGSAP } from '@gsap/react'
import { SplitText } from 'gsap/SplitText'
import { getSteamFromConfig, getSteamToConfig, mmConfig } from '/util/gsapHelpers'

import { images } from '/images'
import styles from './Introduction.css'

gsap.registerPlugin(ScrollTrigger, SplitText)

export function Introduction() {
  const componentRef = React.useRef(null)
  const layoutRef = React.useRef(null)
  const headerRef = React.useRef(null)
  const subheadingRef = React.useRef(null)
  const headingRef = React.useRef(null)

  useGSAP(
    () => {
      const mm = gsap.matchMedia()

      const heading = new SplitText(headingRef.current, { type: 'words' })

      mm.add(mmConfig,
        (ctx) => {
          const { isMobile } = ctx.conditions
          const tlImagesParallax = gsap.timeline({ defaults: { ease: 'power1.inOut' } })
          const tlPeper = gsap.timeline()
          tlPeper.to('[data-peper]', { y: 50, rotate: 20, yoyoEase: 'sine.inOut', duration: 10, repeat: -1, ease: 'linear' }, 0)
          tlPeper.fromTo('[data-babymais]', { rotate: 20 }, { y: -30, rotate: -10, yoyoEase: 'sine.inOut', duration: 10, repeat: -1, ease: 'linear' }, 0)
          const tlText = gsap.timeline({
            scrollTrigger: {
              trigger: headerRef.current,
              start: 'top 75%',
            }
          })

          tlText
            .set(headerRef.current, { autoAlpha: 1 })
            .fromTo(subheadingRef.current, { opacity: 0 }, { opacity: 1, duration: 1.5, ease: 'power1.out' })
            .fromTo( heading.words, getSteamFromConfig(isMobile), getSteamToConfig({ each: 0.10 }), '-=1.2' )

          tlImagesParallax
            .to('[data-image1-container]', { y: isMobile ? -100 : -200 }, 0)
            .to('[data-image2-container]', { y: isMobile ? -80 : -160 }, 0)
            .to('[data-image3-container]', { y: isMobile ? -25 : -50 }, 0)
            .to('[data-image4-container]', { y: isMobile ? -80 : -160 }, 0)
            .to('[data-image5-container]', { y: isMobile ? -25 : -50 }, 0)
            .to('[data-image6-container]', { y: isMobile ? -80 : -160 }, 0)
            .to('[data-image7-container]', { y: isMobile ? -100 : -200 }, 0)
            .to('[data-image8-container]', { y: isMobile ? -200 : -400 }, 0)
            .to('[data-image9-container]', { y: isMobile ? -200 : -400 }, 0)

          ScrollTrigger.create({
            trigger: componentRef.current,
            start: 'top bottom',
            scrub: 4,
            animation: tlImagesParallax,
            onEnterBack: () => tlPeper.play(),
            onLeave: () => tlPeper.pause(),
          })
        }
      )

    }, { scope: componentRef })

  return (
    <article className={styles.component} ref={componentRef}>
      <div
        className={styles.layout}
        ref={layoutRef}
      >
        <header className={styles.header} ref={headerRef}>
          <p className={styles.subheading} ref={subheadingRef}>Bij Albron bereiden we elk gerecht met</p>
          <h2 className={styles.heading} ref={headingRef} data-asdasd>ons unieke recept<br />voor sociale impact.</h2>
        </header>
        <div className={cx(styles.imageContainer, styles.imageContainer1)} data-image1-container>
          <img className={styles.image} src={images.introductionImage1} alt='Vrouw die koffie drinkt' />
        </div>
        <div className={cx(styles.imageContainer, styles.imageContainer2)} data-image2-container>
          <img className={styles.image} src={images.introductionImage2} alt='Twee lachende mensen' />
        </div>
        <div className={cx(styles.imageContainer, styles.imageContainer3)} data-image3-container>
          <img className={styles.image} src={images.introductionImage3} alt='Vrouw met sandwich' />
        </div>
        <div className={cx(styles.imageContainer, styles.imageContainer4)} data-image4-container>
          <img className={styles.image} src={images.introductionImage4} alt='Vrouw met ijskoffie' />
        </div>
        <div className={cx(styles.imageContainer, styles.imageContainer5)} data-image5-container>
          <img className={styles.image} src={images.introductionImage5} alt='Man met thee' />
        </div>
        <div className={cx(styles.imageContainer, styles.imageContainer6)} data-image6-container>
          <img className={styles.image} src={images.introductionImage6} alt='Man met sandwich' />
        </div>
        <div className={cx(styles.imageContainer, styles.imageContainer7)} data-image7-container>
          <img className={styles.image} src={images.introductionImage7} alt='Chef' />
        </div>
        <div className={styles.peperContainer} data-image8-container>
          <img src={images.introductionPeper} alt='Rode peper' data-peper />
        </div>
        <div className={styles.babyMaisContainer} data-image9-container>
          <img src={images.introductionBabyMais} alt='Baby mais' data-babymais />
        </div>
      </div>
    </article>
  )
}
